var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: { title: _vm.creativeFormTitle, closable: "" },
    on: { submit: _vm.onFormSubmit, cancel: _vm.closeModal },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-wrap lg:flex-no-wrap" },
              [
                _c(
                  "div",
                  {
                    staticClass: "w-full lg:pr-2 flex justify-center",
                    class: { "lg:w-1/2": _vm.isEditing },
                  },
                  [
                    _vm.creative && _vm.creative.url
                      ? _c("div", { staticClass: "relative" }, [
                          _c("img", {
                            attrs: {
                              alt: "Creative image",
                              src: _vm.creative.url,
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass:
                                "absolute right-0 top-0 bg-gray-200 hover:bg-gray-400 p-1 rounded",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.creative.url = null
                                },
                              },
                            },
                            [_c("close-svg", { staticClass: "w-4 h-4" })],
                            1
                          ),
                        ])
                      : _c("sun-file-uploader", {
                          attrs: {
                            id: "file-upload",
                            accept: "image/*",
                            multiple: !_vm.isEditing,
                            required: "required",
                          },
                          on: { change: _vm.onFileUpload },
                        }),
                  ],
                  1
                ),
                _vm.isEditing
                  ? _c("code-editor", {
                      staticClass: "w-full lg:w-1/2 lg:pl-2",
                      attrs: { readonly: "" },
                      model: {
                        value: _vm.creative.tagsTemplate,
                        callback: function ($$v) {
                          _vm.$set(_vm.creative, "tagsTemplate", $$v)
                        },
                        expression: "creative.tagsTemplate",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("creative-modal-footer", {
              attrs: { loading: _vm.isLoading },
              on: { cancel: _vm.closeModal },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }