<template>
  <asterix-form-modal :title="creativeFormTitle" closable @submit="onFormSubmit" @cancel="closeModal">
    <template #content>
      <div class="flex flex-wrap lg:flex-no-wrap">
        <div class="w-full lg:pr-2 flex justify-center" :class="{ 'lg:w-1/2': isEditing }">
          <div v-if="creative && creative.url" class="relative">
            <img alt="Creative image" :src="creative.url" />
            <button
              class="absolute right-0 top-0 bg-gray-200 hover:bg-gray-400 p-1 rounded"
              @click.prevent="creative.url = null"
            >
              <close-svg class="w-4 h-4" />
            </button>
          </div>
          <sun-file-uploader
            v-else
            id="file-upload"
            accept="image/*"
            :multiple="!isEditing"
            required="required"
            @change="onFileUpload"
          />
        </div>

        <code-editor v-if="isEditing" v-model="creative.tagsTemplate" class="w-full lg:w-1/2 lg:pl-2" readonly />
      </div>
    </template>
    <template #footer>
      <creative-modal-footer :loading="isLoading" @cancel="closeModal" />
    </template>
  </asterix-form-modal>
</template>

<script>
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import CloseSvg from '@/components/icons/CloseSvg';

export default {
  name: 'CreativeBannerModal',
  components: {
    CloseSvg,
    AsterixFormModal,
    CodeEditor: () => import('@/components/organisms/shared/CodeEditor.vue'),
    CreativeModalFooter: () => import('@/components/molecules/shared/AsterixFooterModal'),
  },
  props: {
    /** @type CreativeBanner */
    creative: {
      type: Object,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedPublisher: null,
      fileInputError: false,
      deleted: [],
      showFormModal: false,
      filesToUpload: [],
    };
  },
  computed: {
    isEditing() {
      return !!this.creative;
    },
    creativeFormTitle() {
      return this.isEditing ? 'Edit creative' : 'New creative';
    },
  },
  methods: {
    onFileUpload(files) {
      this.filesToUpload = files;
    },
    closeModal() {
      this.$emit('cancel');
    },
    async onFormSubmit({ valid }) {
      if (valid && this.filesToUpload?.length) {
        this.$emit('submit', this.filesToUpload);
      }
    },
  },
};
</script>
